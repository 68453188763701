<template>
  <div class="container-fluid">
    <div class="mt-4 col-md-3 text-left">
      <h3>Survey Reports</h3>
      <hr class="mt-n2">
    </div>
    <div class="row">
      <div class="shadow mt-2 ml-3 p-3 col-6 float-left mb-5">
        <bar-chart :data="isrsProcessChartData" :stacked="true" :max="100" :colors="['#00CC00','#FFCC00','#C00000','#E8E8E8']" height="350px"></bar-chart>
      </div>
      <div class="shadow mt-2 ml-3 p-3 col-5 float-left mb-5">
        <pie-chart :data="orgSurveyProgressData" :colors="['#006600','#0033CC','#686868']"></pie-chart>
      </div>
    </div>
    <div class="row">
      <div class="col-6 mt-1 float-left">
        <table class="table table-hover table-bordered shadow table-sm">
          <thead>
            <tr>
              <th>#</th>
              <th class="text-left">ISRS Process</th>
              <th>Favourable %</th>
              <th>Neutral %</th>
              <th>Unfavourable %</th>
              <th>NA %</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="isrs in this.isrsProcessTableData" :key="isrs.ID">
              <td>{{isrs.ID}}</td>
              <td class="text-left">{{isrs.title}}</td>
              <td :style="[isrs.favourable < 60 ? {'background-color': 'rgba(192, 0, 0, 0.5)'} : (isrs.favourable > 59 && isrs.favourable < 81) ? {'background-color': 'rgba(255, 204, 0, 0.5)'} : {}]">{{isrs.favourable}}</td>
              <td>{{isrs.neutral}}</td>
              <td>{{isrs.unfavourable}}</td>
              <td>{{isrs.na}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-6 ml-0 p-1 float-left">
          <table class="table table-hover table-bordered shadow table-sm col-11 ml-2">
            <thead>
              <tr>
                <th>Favourable %</th>
                <th>Perceived status of the Business process</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>&gt;80%</td>
                <td>(At least) Basic business process is in place.</td>
              </tr>
              <tr style="background-color:rgba(255, 204, 0, 0.5)">
                <td>60% - 80%</td>
                <td>Business process needs to be assessed for effectiveness.</td>
              </tr>
              <tr style="background-color:rgba(192, 0, 0, 0.5)">
                <td>&lt;60%</td>
                <td>Potential lack of control in the business process.</td>
              </tr>
            </tbody>
          </table>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapActions} from 'vuex'


export default Vue.extend({
  name : 'trends',
  data () {
    return {
      isrsProcessChartData : [],
      orgSurveyProgressData : [],
      isrsProcessTableData : []
    }
  },
  async mounted() {
    await this.fetchIsrsProcessChartData({
      OrganisationId : this.getAuthenticatedOrganisation.ID
    })
    this.isrsProcessChartData = this.getIsrsProcessChartData

    await this.fetchOrgSurveyProgress({
      OrganisationId : this.getAuthenticatedOrganisation.ID
    })
    this.orgSurveyProgressData = this.getOrgSurveyProgress

    await this.fetchIsrsProcessTableData({
      OrganisationId : this.getAuthenticatedOrganisation.ID
    })
    this.isrsProcessTableData = this.getIsrsProcessTableData

  },
  computed: {
    ...mapGetters('trend', ['getIsrsProcessChartData','getOrgSurveyProgress','getIsrsProcessTableData']),
    ...mapGetters('survey',['getAuthenticatedOrganisation'])
  },
  methods:{
    ...mapActions('trend',['fetchIsrsProcessChartData', 'fetchOrgSurveyProgress','fetchIsrsProcessTableData'])
  }
})
</script>

<style scoped></style>
